<template>
  <!--begin::Card Body-->
  <div class="card-body p-10">
    <div class="row align-items-center desktop">
      <div class="col-12 ">
        <div class="formacion row">
          <div class="col-1">
            <div class="textoformacion">FORMACIÓN</div>
          </div>
          <div class="col-11 pl-2 padimagen">
        <img
          src="../../public/media/illustrations/portada2.jpg"
          alt="iamformacion"
          class="img-fluid img-portada card"
        />
        </div>
      </div>
    </div>
      <div class="espacio"></div>
      <div class="mt-5 fs-1">{{ $t("cursosFormacionMujeres") }}</div>
      <div class="mt-2 fs-4">
        <p>
          Bienvenido/a al portal del Instituto Aragonés de la Mujer, donde te ofrecemos una amplia variedad de acciones formativas, concretamente <strong>12 cursos</strong> destinados a promover la igualdad de género, el desarrollo profesional y
          digital de las mujeres emprendedoras y en búsqueda de empleo, así como de las empresas y las organizaciones.
        </p>
        <p>
          Los cursos están diseñados para potenciar tus habilidades, afrontar procedimientos y/o ampliar conocimiento en las siguientes 4 áreas temáticas:
        </p>
        <ul>
          <li>ORIENTACIÓN Y EMPLEO</li>
          <li>DIGITAL</li>
          <li>EMPRENDIMIENTO</li>
          <li>IGUALDAD EN LA EMPRESA</li>
        </ul>
        <p>
          Todas las acciones se desarrollan en modalidad de <strong>aula virtual</strong>, son <strong>gratuitas</strong> y tienen una duración de <strong>2 horas</strong>. Los cursos se dirigen a diferentes perfiles, en función del objetivo de cada acción, concretándose en la descripción de cada curso.
          <br><br>
          Además, quienes participan en los cursos pueden reforzar su formación con consultas personalizadas a través de las Asesorías Empresarial y Laboral del IAM.
          Nuestro objetivo es proporcionarte herramientas prácticas para tu crecimiento personal y profesional o las claves y pautas para la implantación de procedimientos que han de cumplir las empresas en materia de igualdad.
          <br><br>
          Si tienes dudas, puedes consultar en la plataforma en “Preguntas frecuentes”, a través del teléfono 976 716 720 o mediante el correo: <a href="mailto:iamformacion@aragon.es">iamformacion@aragon.es</a>.
          <br><br>
          Únete al <strong>IAM Formación</strong> en nuestro compromiso por avanzar hacia una sociedad más equitativa y empoderada.
        </p>
        <p><a href="/docs/ProgramaPlandeFormacion2024.pdf" target="blank"><i class="fa-regular fa-file-pdf fa-xl colorAzul me-2"></i>Programa Plan de Formación 2024.pdf</a></p>
      </div>
    </div>
  </div>
  <!--begin::Card Body-->
</template>

// Añadimos estilos inline
<style scoped>

/* div de la seccion azul de formacion */
.formacion{
  background-color: #00369A;
  width: 95%;
  height: 390px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: absolute;
  margin-left: 5%;
  padding: 25px;
}

/* img de portada, dentro del div de formacion */
.img-portada {
  width: 70%;
  height: 340px;
  object-fit: cover;

  /* en movil no se muestra */
  @media (max-width: 768px) {
    display: none;
  }
}

.padimagen{
  @media (max-width: 1000px) {
    padding-left: 25px;
  }
}

/* da cuerpo al espacio que ocupa el div azul de formacion */
.espacio{
  width: 80%;
  height: 390px;
}

/* formato del texto invertido vertical y fuente */
.textoformacion{
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  transform-origin: center center;
  font-size: 59px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.colorAzul{
  color: #00369A;
}

</style>
