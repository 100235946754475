const localidades = [
  {
    localidad: "Alfamén",
    provincia: "Zaragoza",
  },
  {
    localidad: "Aísa",
    provincia: "Huesca",
  },
  {
    localidad: "Ansó",
    provincia: "Huesca",
  },
  {
    localidad: "Aragüés del Puerto",
    provincia: "Huesca",
  },
  {
    localidad: "Bailo",
    provincia: "Huesca",
  },
  {
    localidad: "Borau",
    provincia: "Huesca",
  },
  {
    localidad: "Canal de Berdún",
    provincia: "Huesca",
  },
  {
    localidad: "Canfranc",
    provincia: "Huesca",
  },
  {
    localidad: "Castiello de Jaca",
    provincia: "Huesca",
  },
  {
    localidad: "Fago",
    provincia: "Huesca",
  },
  {
    localidad: "Jaca",
    provincia: "Huesca",
  },
  {
    localidad: "Jasa",
    provincia: "Huesca",
  },
  {
    localidad: "Santa Cilia",
    provincia: "Huesca",
  },
  {
    localidad: "Santa Cruz de la Serós",
    provincia: "Huesca",
  },
  {
    localidad: "Villanúa",
    provincia: "Huesca",
  },
  {
    localidad: "Valle de Hecho",
    provincia: "Huesca",
  },
  {
    localidad: "Puente la Reina de Jaca",
    provincia: "Huesca",
  },
  {
    localidad: "Artieda",
    provincia: "Zaragoza",
  },
  {
    localidad: "Mianos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Salvatierra de Esca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sigüés",
    provincia: "Zaragoza",
  },
  {
    localidad: "Biescas",
    provincia: "Huesca",
  },
  {
    localidad: "Caldearenas",
    provincia: "Huesca",
  },
  {
    localidad: "Hoz de Jaca",
    provincia: "Huesca",
  },
  {
    localidad: "Panticosa",
    provincia: "Huesca",
  },
  {
    localidad: "Sabiñánigo",
    provincia: "Huesca",
  },
  {
    localidad: "Sallent de Gállego",
    provincia: "Huesca",
  },
  {
    localidad: "Yebra de Basa",
    provincia: "Huesca",
  },
  {
    localidad: "Yésero",
    provincia: "Huesca",
  },
  {
    localidad: "Abizanda",
    provincia: "Huesca",
  },
  {
    localidad: "Bárcabo",
    provincia: "Huesca",
  },
  {
    localidad: "Bielsa",
    provincia: "Huesca",
  },
  {
    localidad: "Boltaña",
    provincia: "Huesca",
  },
  {
    localidad: "Broto",
    provincia: "Huesca",
  },
  {
    localidad: "Fanlo",
    provincia: "Huesca",
  },
  {
    localidad: "Fiscal",
    provincia: "Huesca",
  },
  {
    localidad: "Fueva (La)",
    provincia: "Huesca",
  },
  {
    localidad: "Gistaín",
    provincia: "Huesca",
  },
  {
    localidad: "Labuerda",
    provincia: "Huesca",
  },
  {
    localidad: "Laspuña",
    provincia: "Huesca",
  },
  {
    localidad: "Palo",
    provincia: "Huesca",
  },
  {
    localidad: "Plan",
    provincia: "Huesca",
  },
  {
    localidad: "Puértolas",
    provincia: "Huesca",
  },
  {
    localidad: "Pueyo de Araguás (El)",
    provincia: "Huesca",
  },
  {
    localidad: "San Juan de Plan",
    provincia: "Huesca",
  },
  {
    localidad: "Tella-Sin",
    provincia: "Huesca",
  },
  {
    localidad: "Torla-Ordesa",
    provincia: "Huesca",
  },
  {
    localidad: "Aínsa-Sobrarbe",
    provincia: "Huesca",
  },
  {
    localidad: "Arén",
    provincia: "Huesca",
  },
  {
    localidad: "Benabarre",
    provincia: "Huesca",
  },
  {
    localidad: "Benasque",
    provincia: "Huesca",
  },
  {
    localidad: "Bisaurri",
    provincia: "Huesca",
  },
  {
    localidad: "Bonansa",
    provincia: "Huesca",
  },
  {
    localidad: "Campo",
    provincia: "Huesca",
  },
  {
    localidad: "Capella",
    provincia: "Huesca",
  },
  {
    localidad: "Castejón de Sos",
    provincia: "Huesca",
  },
  {
    localidad: "Castigaleu",
    provincia: "Huesca",
  },
  {
    localidad: "Chía",
    provincia: "Huesca",
  },
  {
    localidad: "Estopiñán del Castillo",
    provincia: "Huesca",
  },
  {
    localidad: "Foradada del Toscar",
    provincia: "Huesca",
  },
  {
    localidad: "Graus",
    provincia: "Huesca",
  },
  {
    localidad: "Isábena",
    provincia: "Huesca",
  },
  {
    localidad: "Lascuarre",
    provincia: "Huesca",
  },
  {
    localidad: "Laspaúles",
    provincia: "Huesca",
  },
  {
    localidad: "Monesma y Cajigar",
    provincia: "Huesca",
  },
  {
    localidad: "Montanuy",
    provincia: "Huesca",
  },
  {
    localidad: "Perarrúa",
    provincia: "Huesca",
  },
  {
    localidad: "Puebla de Castro (La)",
    provincia: "Huesca",
  },
  {
    localidad: "Puente de Montañana",
    provincia: "Huesca",
  },
  {
    localidad: "Sahún",
    provincia: "Huesca",
  },
  {
    localidad: "Santaliestra y San Quílez",
    provincia: "Huesca",
  },
  {
    localidad: "Secastilla",
    provincia: "Huesca",
  },
  {
    localidad: "Seira",
    provincia: "Huesca",
  },
  {
    localidad: "Sesué",
    provincia: "Huesca",
  },
  {
    localidad: "Sopeira",
    provincia: "Huesca",
  },
  {
    localidad: "Tolva",
    provincia: "Huesca",
  },
  {
    localidad: "Torre la Ribera",
    provincia: "Huesca",
  },
  {
    localidad: "Valle de Bardají",
    provincia: "Huesca",
  },
  {
    localidad: "Valle de Lierp",
    provincia: "Huesca",
  },
  {
    localidad: "Beranuy",
    provincia: "Huesca",
  },
  {
    localidad: "Viacamp y Litera",
    provincia: "Huesca",
  },
  {
    localidad: "Villanova",
    provincia: "Huesca",
  },
  {
    localidad: "Ardisa",
    provincia: "Zaragoza",
  },
  {
    localidad: "Asín",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bagüés",
    provincia: "Zaragoza",
  },
  {
    localidad: "Biota",
    provincia: "Zaragoza",
  },
  {
    localidad: "Castejón de Valdejasa",
    provincia: "Zaragoza",
  },
  {
    localidad: "Castiliscar",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ejea de los Caballeros",
    provincia: "Zaragoza",
  },
  {
    localidad: "Erla",
    provincia: "Zaragoza",
  },
  {
    localidad: "Frago (El)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Isuerre",
    provincia: "Zaragoza",
  },
  {
    localidad: "Layana",
    provincia: "Zaragoza",
  },
  {
    localidad: "Lobera de Onsella",
    provincia: "Zaragoza",
  },
  {
    localidad: "Longás",
    provincia: "Zaragoza",
  },
  {
    localidad: "Luesia",
    provincia: "Zaragoza",
  },
  {
    localidad: "Luna",
    provincia: "Zaragoza",
  },
  {
    localidad: "Navardún",
    provincia: "Zaragoza",
  },
  {
    localidad: "Orés",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pedrosas (Las)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Piedratajada",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pintanos (Los)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Puendeluna",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sádaba",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sierra de Luna",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sos del Rey Católico",
    provincia: "Zaragoza",
  },
  {
    localidad: "Tauste",
    provincia: "Zaragoza",
  },
  {
    localidad: "Uncastillo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Undués de Lerda",
    provincia: "Zaragoza",
  },
  {
    localidad: "Urriés",
    provincia: "Zaragoza",
  },
  {
    localidad: "Valpalmas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Biel",
    provincia: "Zaragoza",
  },
  {
    localidad: "Marracos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Agüero",
    provincia: "Huesca",
  },
  {
    localidad: "Albero Alto",
    provincia: "Huesca",
  },
  {
    localidad: "Alcalá de Gurrea",
    provincia: "Huesca",
  },
  {
    localidad: "Alcalá del Obispo",
    provincia: "Huesca",
  },
  {
    localidad: "Alerre",
    provincia: "Huesca",
  },
  {
    localidad: "Almudévar",
    provincia: "Huesca",
  },
  {
    localidad: "Angüés",
    provincia: "Huesca",
  },
  {
    localidad: "Antillón",
    provincia: "Huesca",
  },
  {
    localidad: "Argavieso",
    provincia: "Huesca",
  },
  {
    localidad: "Arguis",
    provincia: "Huesca",
  },
  {
    localidad: "Ayerbe",
    provincia: "Huesca",
  },
  {
    localidad: "Banastás",
    provincia: "Huesca",
  },
  {
    localidad: "Biscarrués",
    provincia: "Huesca",
  },
  {
    localidad: "Blecua y Torres",
    provincia: "Huesca",
  },
  {
    localidad: "Casbas de Huesca",
    provincia: "Huesca",
  },
  {
    localidad: "Chimillas",
    provincia: "Huesca",
  },
  {
    localidad: "Gurrea de Gállego",
    provincia: "Huesca",
  },
  {
    localidad: "Huesca",
    provincia: "Huesca",
  },
  {
    localidad: "Ibieca",
    provincia: "Huesca",
  },
  {
    localidad: "Igriés",
    provincia: "Huesca",
  },
  {
    localidad: "Loarre",
    provincia: "Huesca",
  },
  {
    localidad: "Loporzano",
    provincia: "Huesca",
  },
  {
    localidad: "Loscorrales",
    provincia: "Huesca",
  },
  {
    localidad: "Monflorite-Lascasas",
    provincia: "Huesca",
  },
  {
    localidad: "Novales",
    provincia: "Huesca",
  },
  {
    localidad: "Nueno",
    provincia: "Huesca",
  },
  {
    localidad: "Peñas de Riglos (Las)",
    provincia: "Huesca",
  },
  {
    localidad: "Pertusa",
    provincia: "Huesca",
  },
  {
    localidad: "Piracés",
    provincia: "Huesca",
  },
  {
    localidad: "Quicena",
    provincia: "Huesca",
  },
  {
    localidad: "Salillas",
    provincia: "Huesca",
  },
  {
    localidad: "Sesa",
    provincia: "Huesca",
  },
  {
    localidad: "Siétamo",
    provincia: "Huesca",
  },
  {
    localidad: "Tierz",
    provincia: "Huesca",
  },
  {
    localidad: "Tramaced",
    provincia: "Huesca",
  },
  {
    localidad: "Vicién",
    provincia: "Huesca",
  },
  {
    localidad: "Sotonera (La)",
    provincia: "Huesca",
  },
  {
    localidad: "Lupiñén-Ortilla",
    provincia: "Huesca",
  },
  {
    localidad: "Murillo de Gállego",
    provincia: "Zaragoza",
  },
  {
    localidad: "Santa Eulalia de Gállego",
    provincia: "Zaragoza",
  },
  {
    localidad: "Abiego",
    provincia: "Huesca",
  },
  {
    localidad: "Adahuesca",
    provincia: "Huesca",
  },
  {
    localidad: "Alquézar",
    provincia: "Huesca",
  },
  {
    localidad: "Azara",
    provincia: "Huesca",
  },
  {
    localidad: "Azlor",
    provincia: "Huesca",
  },
  {
    localidad: "Barbastro",
    provincia: "Huesca",
  },
  {
    localidad: "Barbuñales",
    provincia: "Huesca",
  },
  {
    localidad: "Berbegal",
    provincia: "Huesca",
  },
  {
    localidad: "Bierge",
    provincia: "Huesca",
  },
  {
    localidad: "Castejón del Puente",
    provincia: "Huesca",
  },
  {
    localidad: "Castillazuelo",
    provincia: "Huesca",
  },
  {
    localidad: "Colungo",
    provincia: "Huesca",
  },
  {
    localidad: "Estada",
    provincia: "Huesca",
  },
  {
    localidad: "Estadilla",
    provincia: "Huesca",
  },
  {
    localidad: "Grado (El)",
    provincia: "Huesca",
  },
  {
    localidad: "Ilche",
    provincia: "Huesca",
  },
  {
    localidad: "Laluenga",
    provincia: "Huesca",
  },
  {
    localidad: "Laperdiguera",
    provincia: "Huesca",
  },
  {
    localidad: "Lascellas-Ponzano",
    provincia: "Huesca",
  },
  {
    localidad: "Naval",
    provincia: "Huesca",
  },
  {
    localidad: "Olvena",
    provincia: "Huesca",
  },
  {
    localidad: "Peralta de Alcofea",
    provincia: "Huesca",
  },
  {
    localidad: "Peraltilla",
    provincia: "Huesca",
  },
  {
    localidad: "Pozán de Vero",
    provincia: "Huesca",
  },
  {
    localidad: "Salas Altas",
    provincia: "Huesca",
  },
  {
    localidad: "Salas Bajas",
    provincia: "Huesca",
  },
  {
    localidad: "Torres de Alcanadre",
    provincia: "Huesca",
  },
  {
    localidad: "Santa María de Dulcis",
    provincia: "Huesca",
  },
  {
    localidad: "Hoz y Costeán",
    provincia: "Huesca",
  },
  {
    localidad: "Albalate de Cinca",
    provincia: "Huesca",
  },
  {
    localidad: "Alcolea de Cinca",
    provincia: "Huesca",
  },
  {
    localidad: "Alfántega",
    provincia: "Huesca",
  },
  {
    localidad: "Almunia de San Juan",
    provincia: "Huesca",
  },
  {
    localidad: "Binaced",
    provincia: "Huesca",
  },
  {
    localidad: "Fonz",
    provincia: "Huesca",
  },
  {
    localidad: "Monzón",
    provincia: "Huesca",
  },
  {
    localidad: "Pueyo de Santa Cruz",
    provincia: "Huesca",
  },
  {
    localidad: "San Miguel del Cinca",
    provincia: "Huesca",
  },
  {
    localidad: "Albelda",
    provincia: "Huesca",
  },
  {
    localidad: "Alcampell",
    provincia: "Huesca",
  },
  {
    localidad: "Altorricón",
    provincia: "Huesca",
  },
  {
    localidad: "Azanuy-Alins",
    provincia: "Huesca",
  },
  {
    localidad: "Baells",
    provincia: "Huesca",
  },
  {
    localidad: "Baldellou",
    provincia: "Huesca",
  },
  {
    localidad: "Binéfar",
    provincia: "Huesca",
  },
  {
    localidad: "Camporrells",
    provincia: "Huesca",
  },
  {
    localidad: "Castillonroy",
    provincia: "Huesca",
  },
  {
    localidad: "Esplús",
    provincia: "Huesca",
  },
  {
    localidad: "Peralta de Calasanz",
    provincia: "Huesca",
  },
  {
    localidad: "San Esteban de Litera",
    provincia: "Huesca",
  },
  {
    localidad: "Tamarite de Litera",
    provincia: "Huesca",
  },
  {
    localidad: "Vencillón",
    provincia: "Huesca",
  },
  {
    localidad: "Albalatillo",
    provincia: "Huesca",
  },
  {
    localidad: "Albero Bajo",
    provincia: "Huesca",
  },
  {
    localidad: "Alberuela de Tubo",
    provincia: "Huesca",
  },
  {
    localidad: "Alcubierre",
    provincia: "Huesca",
  },
  {
    localidad: "Almuniente",
    provincia: "Huesca",
  },
  {
    localidad: "Barbués",
    provincia: "Huesca",
  },
  {
    localidad: "Capdesaso",
    provincia: "Huesca",
  },
  {
    localidad: "Castejón de Monegros",
    provincia: "Huesca",
  },
  {
    localidad: "Castelflorite",
    provincia: "Huesca",
  },
  {
    localidad: "Grañén",
    provincia: "Huesca",
  },
  {
    localidad: "Huerto",
    provincia: "Huesca",
  },
  {
    localidad: "Lalueza",
    provincia: "Huesca",
  },
  {
    localidad: "Lanaja",
    provincia: "Huesca",
  },
  {
    localidad: "Peñalba",
    provincia: "Huesca",
  },
  {
    localidad: "Poleñino",
    provincia: "Huesca",
  },
  {
    localidad: "Robres",
    provincia: "Huesca",
  },
  {
    localidad: "Sangarrén",
    provincia: "Huesca",
  },
  {
    localidad: "Sariñena",
    provincia: "Huesca",
  },
  {
    localidad: "Sena",
    provincia: "Huesca",
  },
  {
    localidad: "Senés de Alcubierre",
    provincia: "Huesca",
  },
  {
    localidad: "Tardienta",
    provincia: "Huesca",
  },
  {
    localidad: "Torralba de Aragón",
    provincia: "Huesca",
  },
  {
    localidad: "Torres de Barbués",
    provincia: "Huesca",
  },
  {
    localidad: "Valfarta",
    provincia: "Huesca",
  },
  {
    localidad: "Villanueva de Sigena",
    provincia: "Huesca",
  },
  {
    localidad: "Almolda (La)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bujaraloz",
    provincia: "Zaragoza",
  },
  {
    localidad: "Farlete",
    provincia: "Zaragoza",
  },
  {
    localidad: "Leciñena",
    provincia: "Zaragoza",
  },
  {
    localidad: "Monegrillo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Perdiguera",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ballobar",
    provincia: "Huesca",
  },
  {
    localidad: "Belver de Cinca",
    provincia: "Huesca",
  },
  {
    localidad: "Candasnos",
    provincia: "Huesca",
  },
  {
    localidad: "Chalamera",
    provincia: "Huesca",
  },
  {
    localidad: "Fraga",
    provincia: "Huesca",
  },
  {
    localidad: "Ontiñena",
    provincia: "Huesca",
  },
  {
    localidad: "Osso de Cinca",
    provincia: "Huesca",
  },
  {
    localidad: "Torrente de Cinca",
    provincia: "Huesca",
  },
  {
    localidad: "Velilla de Cinca",
    provincia: "Huesca",
  },
  {
    localidad: "Zaidín",
    provincia: "Huesca",
  },
  {
    localidad: "Mequinenza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Agón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ainzón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alberite de San Juan",
    provincia: "Zaragoza",
  },
  {
    localidad: "Albeta",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ambel",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bisimbre",
    provincia: "Zaragoza",
  },
  {
    localidad: "Borja",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bulbuente",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bureta",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fréscano",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fuendejalón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Magallón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Maleján",
    provincia: "Zaragoza",
  },
  {
    localidad: "Mallén",
    provincia: "Zaragoza",
  },
  {
    localidad: "Novillas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pozuelo de Aragón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Tabuenca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Talamantes",
    provincia: "Zaragoza",
  },
  {
    localidad: "Aranda de Moncayo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Brea de Aragón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Calcena",
    provincia: "Zaragoza",
  },
  {
    localidad: "Gotor",
    provincia: "Zaragoza",
  },
  {
    localidad: "Illueca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Jarque de Moncayo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Mesones de Isuela",
    provincia: "Zaragoza",
  },
  {
    localidad: "Oseja",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pomer",
    provincia: "Zaragoza",
  },
  {
    localidad: "Purujosa",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sestrica",
    provincia: "Zaragoza",
  },
  {
    localidad: "Tierga",
    provincia: "Zaragoza",
  },
  {
    localidad: "Trasobares",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alagón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alcalá de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bárboles",
    provincia: "Zaragoza",
  },
  {
    localidad: "Boquiñeni",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cabañas de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Figueruelas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Gallur",
    provincia: "Zaragoza",
  },
  {
    localidad: "Grisén",
    provincia: "Zaragoza",
  },
  {
    localidad: "Joyosa (La)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Luceni",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pedrola",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pinseque",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pleitas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pradilla de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Remolinos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sobradiel",
    provincia: "Zaragoza",
  },
  {
    localidad: "Torres de Berrellén",
    provincia: "Zaragoza",
  },
  {
    localidad: "Almonacid de la Sierra",
    provincia: "Zaragoza",
  },
  {
    localidad: "Almunia de Doña Godina (La)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alpartir",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bardallur",
    provincia: "Zaragoza",
  },
  {
    localidad: "Calatorao",
    provincia: "Zaragoza",
  },
  {
    localidad: "Chodes",
    provincia: "Zaragoza",
  },
  {
    localidad: "Épila",
    provincia: "Zaragoza",
  },
  {
    localidad: "Lucena de Jalón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Lumpiaque",
    provincia: "Zaragoza",
  },
  {
    localidad: "Morata de Jalón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Muela (La)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Plasencia de Jalón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ricla",
    provincia: "Zaragoza",
  },
  {
    localidad: "Rueda de Jalón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Salillas de Jalón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Santa Cruz de Grío",
    provincia: "Zaragoza",
  },
  {
    localidad: "Urrea de Jalón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alfajarín",
    provincia: "Zaragoza",
  },
  {
    localidad: "Botorrita",
    provincia: "Zaragoza",
  },
  {
    localidad: "Burgo de Ebro (El)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cadrete",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cuarte de Huerva",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fuentes de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Jaulín",
    provincia: "Zaragoza",
  },
  {
    localidad: "María de Huerva",
    provincia: "Zaragoza",
  },
  {
    localidad: "Mediana de Aragón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Mozota",
    provincia: "Zaragoza",
  },
  {
    localidad: "Nuez de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Osera de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pastriz",
    provincia: "Zaragoza",
  },
  {
    localidad: "Puebla de Alfindén (La)",
    provincia: "Zaragoza",
  },
  {
    localidad: "San Mateo de Gállego",
    provincia: "Zaragoza",
  },
  {
    localidad: "Utebo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villafranca de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villanueva de Gállego",
    provincia: "Zaragoza",
  },
  {
    localidad: "Zaragoza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Zuera",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villamayor de Gállego",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alborge",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alforque",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cinco Olivas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Escatrón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Gelsa",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pina de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Quinto",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sástago",
    provincia: "Zaragoza",
  },
  {
    localidad: "Velilla de Ebro",
    provincia: "Zaragoza",
  },
  {
    localidad: "Zaida (La)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Caspe",
    provincia: "Zaragoza",
  },
  {
    localidad: "Chiprana",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fabara",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fayón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Maella",
    provincia: "Zaragoza",
  },
  {
    localidad: "Nonaspe",
    provincia: "Zaragoza",
  },
  {
    localidad: "Abanto",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alarba",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alconchel de Ariza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Alhama de Aragón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Aniñón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Arándiga",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ariza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ateca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Belmonte de Gracián",
    provincia: "Zaragoza",
  },
  {
    localidad: "Berdejo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bijuesca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bordalba",
    provincia: "Zaragoza",
  },
  {
    localidad: "Bubierca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cabolafuente",
    provincia: "Zaragoza",
  },
  {
    localidad: "Calatayud",
    provincia: "Zaragoza",
  },
  {
    localidad: "Calmarza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Campillo de Aragón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Carenas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Castejón de Alarba",
    provincia: "Zaragoza",
  },
  {
    localidad: "Castejón de las Armas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cervera de la Cañada",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cetina",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cimballa",
    provincia: "Zaragoza",
  },
  {
    localidad: "Clarés de Ribota",
    provincia: "Zaragoza",
  },
  {
    localidad: "Codos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Contamina",
    provincia: "Zaragoza",
  },
  {
    localidad: "Embid de Ariza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Frasno (El)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fuentes de Jiloca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Godojos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ibdes",
    provincia: "Zaragoza",
  },
  {
    localidad: "Jaraba",
    provincia: "Zaragoza",
  },
  {
    localidad: "Malanquilla",
    provincia: "Zaragoza",
  },
  {
    localidad: "Maluenda",
    provincia: "Zaragoza",
  },
  {
    localidad: "Mara",
    provincia: "Zaragoza",
  },
  {
    localidad: "Miedes de Aragón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Monreal de Ariza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Monterde",
    provincia: "Zaragoza",
  },
  {
    localidad: "Montón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Morata de Jiloca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Morés",
    provincia: "Zaragoza",
  },
  {
    localidad: "Moros",
    provincia: "Zaragoza",
  },
  {
    localidad: "Munébrega",
    provincia: "Zaragoza",
  },
  {
    localidad: "Nigüella",
    provincia: "Zaragoza",
  },
  {
    localidad: "Nuévalos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Olvés",
    provincia: "Zaragoza",
  },
  {
    localidad: "Orera",
    provincia: "Zaragoza",
  },
  {
    localidad: "Paracuellos de Jiloca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Paracuellos de la Ribera",
    provincia: "Zaragoza",
  },
  {
    localidad: "Pozuel de Ariza",
    provincia: "Zaragoza",
  },
  {
    localidad: "Ruesca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Saviñán",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sediles",
    provincia: "Zaragoza",
  },
  {
    localidad: "Sisamón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Terrer",
    provincia: "Zaragoza",
  },
  {
    localidad: "Tobed",
    provincia: "Zaragoza",
  },
  {
    localidad: "Torralba de Ribota",
    provincia: "Zaragoza",
  },
  {
    localidad: "Torrehermosa",
    provincia: "Zaragoza",
  },
  {
    localidad: "Torrelapaja",
    provincia: "Zaragoza",
  },
  {
    localidad: "Torrijo de la Cañada",
    provincia: "Zaragoza",
  },
  {
    localidad: "Valtorres",
    provincia: "Zaragoza",
  },
  {
    localidad: "Velilla de Jiloca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Vilueña (La)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villafeliche",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villalba de Perejil",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villalengua",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villarroya de la Sierra",
    provincia: "Zaragoza",
  },
  {
    localidad: "Almochuel",
    provincia: "Zaragoza",
  },
  {
    localidad: "Almonacid de la Cuba",
    provincia: "Zaragoza",
  },
  {
    localidad: "Azuara",
    provincia: "Zaragoza",
  },
  {
    localidad: "Belchite",
    provincia: "Zaragoza",
  },
  {
    localidad: "Codo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fuendetodos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Lagata",
    provincia: "Zaragoza",
  },
  {
    localidad: "Lécera",
    provincia: "Zaragoza",
  },
  {
    localidad: "Letux",
    provincia: "Zaragoza",
  },
  {
    localidad: "Moneva",
    provincia: "Zaragoza",
  },
  {
    localidad: "Moyuela",
    provincia: "Zaragoza",
  },
  {
    localidad: "Plenas",
    provincia: "Zaragoza",
  },
  {
    localidad: "Puebla de Albortón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Samper del Salz",
    provincia: "Zaragoza",
  },
  {
    localidad: "Valmadrid",
    provincia: "Zaragoza",
  },
  {
    localidad: "Albalate del Arzobispo",
    provincia: "Teruel",
  },
  {
    localidad: "Azaila",
    provincia: "Teruel",
  },
  {
    localidad: "Castelnou",
    provincia: "Teruel",
  },
  {
    localidad: "Híjar",
    provincia: "Teruel",
  },
  {
    localidad: "Jatiel",
    provincia: "Teruel",
  },
  {
    localidad: "Puebla de Híjar (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Samper de Calanda",
    provincia: "Teruel",
  },
  {
    localidad: "Urrea de Gaén",
    provincia: "Teruel",
  },
  {
    localidad: "Vinaceite",
    provincia: "Teruel",
  },
  {
    localidad: "Acered",
    provincia: "Zaragoza",
  },
  {
    localidad: "Aldehuela de Liestos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Anento",
    provincia: "Zaragoza",
  },
  {
    localidad: "Atea",
    provincia: "Zaragoza",
  },
  {
    localidad: "Badules",
    provincia: "Zaragoza",
  },
  {
    localidad: "Balconchán",
    provincia: "Zaragoza",
  },
  {
    localidad: "Berrueco",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cerveruela",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cubel",
    provincia: "Zaragoza",
  },
  {
    localidad: "Cuerlas (Las)",
    provincia: "Zaragoza",
  },
  {
    localidad: "Daroca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Fombuena",
    provincia: "Zaragoza",
  },
  {
    localidad: "Gallocanta",
    provincia: "Zaragoza",
  },
  {
    localidad: "Herrera de los Navarros",
    provincia: "Zaragoza",
  },
  {
    localidad: "Langa del Castillo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Lechón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Luesma",
    provincia: "Zaragoza",
  },
  {
    localidad: "Mainar",
    provincia: "Zaragoza",
  },
  {
    localidad: "Manchones",
    provincia: "Zaragoza",
  },
  {
    localidad: "Murero",
    provincia: "Zaragoza",
  },
  {
    localidad: "Nombrevilla",
    provincia: "Zaragoza",
  },
  {
    localidad: "Orcajo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Retascón",
    provincia: "Zaragoza",
  },
  {
    localidad: "Romanos",
    provincia: "Zaragoza",
  },
  {
    localidad: "Santed",
    provincia: "Zaragoza",
  },
  {
    localidad: "Torralba de los Frailes",
    provincia: "Zaragoza",
  },
  {
    localidad: "Torralbilla",
    provincia: "Zaragoza",
  },
  {
    localidad: "Used",
    provincia: "Zaragoza",
  },
  {
    localidad: "Valdehorna",
    provincia: "Zaragoza",
  },
  {
    localidad: "Val de San Martín",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villadoz",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villanueva de Jiloca",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villar de los Navarros",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villarreal de Huerva",
    provincia: "Zaragoza",
  },
  {
    localidad: "Villarroya del Campo",
    provincia: "Zaragoza",
  },
  {
    localidad: "Allueva",
    provincia: "Teruel",
  },
  {
    localidad: "Bádenas",
    provincia: "Teruel",
  },
  {
    localidad: "Báguena",
    provincia: "Teruel",
  },
  {
    localidad: "Bañón",
    provincia: "Teruel",
  },
  {
    localidad: "Barrachina",
    provincia: "Teruel",
  },
  {
    localidad: "Bea",
    provincia: "Teruel",
  },
  {
    localidad: "Bello",
    provincia: "Teruel",
  },
  {
    localidad: "Blancas",
    provincia: "Teruel",
  },
  {
    localidad: "Bueña",
    provincia: "Teruel",
  },
  {
    localidad: "Burbáguena",
    provincia: "Teruel",
  },
  {
    localidad: "Calamocha",
    provincia: "Teruel",
  },
  {
    localidad: "Caminreal",
    provincia: "Teruel",
  },
  {
    localidad: "Castejón de Tornos",
    provincia: "Teruel",
  },
  {
    localidad: "Cosa",
    provincia: "Teruel",
  },
  {
    localidad: "Cucalón",
    provincia: "Teruel",
  },
  {
    localidad: "Ferreruela de Huerva",
    provincia: "Teruel",
  },
  {
    localidad: "Fonfría",
    provincia: "Teruel",
  },
  {
    localidad: "Fuentes Claras",
    provincia: "Teruel",
  },
  {
    localidad: "Lagueruela",
    provincia: "Teruel",
  },
  {
    localidad: "Lanzuela",
    provincia: "Teruel",
  },
  {
    localidad: "Loscos",
    provincia: "Teruel",
  },
  {
    localidad: "Monforte de Moyuela",
    provincia: "Teruel",
  },
  {
    localidad: "Monreal del Campo",
    provincia: "Teruel",
  },
  {
    localidad: "Nogueras",
    provincia: "Teruel",
  },
  {
    localidad: "Odón",
    provincia: "Teruel",
  },
  {
    localidad: "Ojos Negros",
    provincia: "Teruel",
  },
  {
    localidad: "Peracense",
    provincia: "Teruel",
  },
  {
    localidad: "Pozuel del Campo",
    provincia: "Teruel",
  },
  {
    localidad: "Rubielos de la Cérida",
    provincia: "Teruel",
  },
  {
    localidad: "San Martín del Río",
    provincia: "Teruel",
  },
  {
    localidad: "Santa Cruz de Nogueras",
    provincia: "Teruel",
  },
  {
    localidad: "Singra",
    provincia: "Teruel",
  },
  {
    localidad: "Tornos",
    provincia: "Teruel",
  },
  {
    localidad: "Torralba de los Sisones",
    provincia: "Teruel",
  },
  {
    localidad: "Torrecilla del Rebollar",
    provincia: "Teruel",
  },
  {
    localidad: "Torre los Negros",
    provincia: "Teruel",
  },
  {
    localidad: "Torrijo del Campo",
    provincia: "Teruel",
  },
  {
    localidad: "Villafranca del Campo",
    provincia: "Teruel",
  },
  {
    localidad: "Villahermosa del Campo",
    provincia: "Teruel",
  },
  {
    localidad: "Villar del Salz",
    provincia: "Teruel",
  },
  {
    localidad: "Alcaine",
    provincia: "Teruel",
  },
  {
    localidad: "Aliaga",
    provincia: "Teruel",
  },
  {
    localidad: "Anadón",
    provincia: "Teruel",
  },
  {
    localidad: "Blesa",
    provincia: "Teruel",
  },
  {
    localidad: "Cañizar del Olivar",
    provincia: "Teruel",
  },
  {
    localidad: "Castel de Cabra",
    provincia: "Teruel",
  },
  {
    localidad: "Cortes de Aragón",
    provincia: "Teruel",
  },
  {
    localidad: "Cuevas de Almudén",
    provincia: "Teruel",
  },
  {
    localidad: "Escucha",
    provincia: "Teruel",
  },
  {
    localidad: "Fuenferrada",
    provincia: "Teruel",
  },
  {
    localidad: "Hinojosa de Jarque",
    provincia: "Teruel",
  },
  {
    localidad: "Hoz de la Vieja (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Huesa del Común",
    provincia: "Teruel",
  },
  {
    localidad: "Jarque de la Val",
    provincia: "Teruel",
  },
  {
    localidad: "Josa",
    provincia: "Teruel",
  },
  {
    localidad: "Maicas",
    provincia: "Teruel",
  },
  {
    localidad: "Martín del Río",
    provincia: "Teruel",
  },
  {
    localidad: "Mezquita de Jarque",
    provincia: "Teruel",
  },
  {
    localidad: "Montalbán",
    provincia: "Teruel",
  },
  {
    localidad: "Muniesa",
    provincia: "Teruel",
  },
  {
    localidad: "Obón",
    provincia: "Teruel",
  },
  {
    localidad: "Palomar de Arroyos",
    provincia: "Teruel",
  },
  {
    localidad: "Plou",
    provincia: "Teruel",
  },
  {
    localidad: "Salcedillo",
    provincia: "Teruel",
  },
  {
    localidad: "Segura de los Baños",
    provincia: "Teruel",
  },
  {
    localidad: "Torre de las Arcas",
    provincia: "Teruel",
  },
  {
    localidad: "Utrillas",
    provincia: "Teruel",
  },
  {
    localidad: "Villanueva del Rebollar de la Sierra",
    provincia: "Teruel",
  },
  {
    localidad: "Vivel del Río Martín",
    provincia: "Teruel",
  },
  {
    localidad: "Zoma (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Alacón",
    provincia: "Teruel",
  },
  {
    localidad: "Alloza",
    provincia: "Teruel",
  },
  {
    localidad: "Andorra",
    provincia: "Teruel",
  },
  {
    localidad: "Ariño",
    provincia: "Teruel",
  },
  {
    localidad: "Crivillén",
    provincia: "Teruel",
  },
  {
    localidad: "Ejulve",
    provincia: "Teruel",
  },
  {
    localidad: "Estercuel",
    provincia: "Teruel",
  },
  {
    localidad: "Gargallo",
    provincia: "Teruel",
  },
  {
    localidad: "Oliete",
    provincia: "Teruel",
  },
  {
    localidad: "Ababuj",
    provincia: "Teruel",
  },
  {
    localidad: "Aguatón",
    provincia: "Teruel",
  },
  {
    localidad: "Aguilar del Alfambra",
    provincia: "Teruel",
  },
  {
    localidad: "Alba",
    provincia: "Teruel",
  },
  {
    localidad: "Alfambra",
    provincia: "Teruel",
  },
  {
    localidad: "Almohaja",
    provincia: "Teruel",
  },
  {
    localidad: "Alobras",
    provincia: "Teruel",
  },
  {
    localidad: "Alpeñés",
    provincia: "Teruel",
  },
  {
    localidad: "Argente",
    provincia: "Teruel",
  },
  {
    localidad: "Camañas",
    provincia: "Teruel",
  },
  {
    localidad: "Camarillas",
    provincia: "Teruel",
  },
  {
    localidad: "Cañada Vellida",
    provincia: "Teruel",
  },
  {
    localidad: "Cascante del Río",
    provincia: "Teruel",
  },
  {
    localidad: "Cedrillas",
    provincia: "Teruel",
  },
  {
    localidad: "Celadas",
    provincia: "Teruel",
  },
  {
    localidad: "Cella",
    provincia: "Teruel",
  },
  {
    localidad: "Corbalán",
    provincia: "Teruel",
  },
  {
    localidad: "Cubla",
    provincia: "Teruel",
  },
  {
    localidad: "Cuervo (El)",
    provincia: "Teruel",
  },
  {
    localidad: "Cuevas Labradas",
    provincia: "Teruel",
  },
  {
    localidad: "Escorihuela",
    provincia: "Teruel",
  },
  {
    localidad: "Fuentes Calientes",
    provincia: "Teruel",
  },
  {
    localidad: "Galve",
    provincia: "Teruel",
  },
  {
    localidad: "Jorcas",
    provincia: "Teruel",
  },
  {
    localidad: "Libros",
    provincia: "Teruel",
  },
  {
    localidad: "Lidón",
    provincia: "Teruel",
  },
  {
    localidad: "Monteagudo del Castillo",
    provincia: "Teruel",
  },
  {
    localidad: "Orrios",
    provincia: "Teruel",
  },
  {
    localidad: "Pancrudo",
    provincia: "Teruel",
  },
  {
    localidad: "Peralejos",
    provincia: "Teruel",
  },
  {
    localidad: "Perales del Alfambra",
    provincia: "Teruel",
  },
  {
    localidad: "Pobo (El)",
    provincia: "Teruel",
  },
  {
    localidad: "Rillo",
    provincia: "Teruel",
  },
  {
    localidad: "Riodeva",
    provincia: "Teruel",
  },
  {
    localidad: "Santa Eulalia",
    provincia: "Teruel",
  },
  {
    localidad: "Teruel",
    provincia: "Teruel",
  },
  {
    localidad: "Tormón",
    provincia: "Teruel",
  },
  {
    localidad: "Torrelacárcel",
    provincia: "Teruel",
  },
  {
    localidad: "Torremocha de Jiloca",
    provincia: "Teruel",
  },
  {
    localidad: "Tramacastiel",
    provincia: "Teruel",
  },
  {
    localidad: "Valacloche",
    provincia: "Teruel",
  },
  {
    localidad: "Veguillas de la Sierra",
    provincia: "Teruel",
  },
  {
    localidad: "Villarquemado",
    provincia: "Teruel",
  },
  {
    localidad: "Villastar",
    provincia: "Teruel",
  },
  {
    localidad: "Villel",
    provincia: "Teruel",
  },
  {
    localidad: "Visiedo",
    provincia: "Teruel",
  },
  {
    localidad: "Allepuz",
    provincia: "Teruel",
  },
  {
    localidad: "Bordón",
    provincia: "Teruel",
  },
  {
    localidad: "Cantavieja",
    provincia: "Teruel",
  },
  {
    localidad: "Cañada de Benatanduz",
    provincia: "Teruel",
  },
  {
    localidad: "Castellote",
    provincia: "Teruel",
  },
  {
    localidad: "Cuba (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Fortanete",
    provincia: "Teruel",
  },
  {
    localidad: "Iglesuela del Cid (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Mirambel",
    provincia: "Teruel",
  },
  {
    localidad: "Miravete de la Sierra",
    provincia: "Teruel",
  },
  {
    localidad: "Molinos",
    provincia: "Teruel",
  },
  {
    localidad: "Pitarque",
    provincia: "Teruel",
  },
  {
    localidad: "Tronchón",
    provincia: "Teruel",
  },
  {
    localidad: "Villarluengo",
    provincia: "Teruel",
  },
  {
    localidad: "Villarroya de los Pinares",
    provincia: "Teruel",
  },
  {
    localidad: "Albarracín",
    provincia: "Teruel",
  },
  {
    localidad: "Bezas",
    provincia: "Teruel",
  },
  {
    localidad: "Bronchales",
    provincia: "Teruel",
  },
  {
    localidad: "Calomarde",
    provincia: "Teruel",
  },
  {
    localidad: "Frías de Albarracín",
    provincia: "Teruel",
  },
  {
    localidad: "Gea de Albarracín",
    provincia: "Teruel",
  },
  {
    localidad: "Griegos",
    provincia: "Teruel",
  },
  {
    localidad: "Guadalaviar",
    provincia: "Teruel",
  },
  {
    localidad: "Jabaloyas",
    provincia: "Teruel",
  },
  {
    localidad: "Monterde de Albarracín",
    provincia: "Teruel",
  },
  {
    localidad: "Moscardón",
    provincia: "Teruel",
  },
  {
    localidad: "Noguera de Albarracín",
    provincia: "Teruel",
  },
  {
    localidad: "Orihuela del Tremedal",
    provincia: "Teruel",
  },
  {
    localidad: "Pozondón",
    provincia: "Teruel",
  },
  {
    localidad: "Ródenas",
    provincia: "Teruel",
  },
  {
    localidad: "Royuela",
    provincia: "Teruel",
  },
  {
    localidad: "Rubiales",
    provincia: "Teruel",
  },
  {
    localidad: "Saldón",
    provincia: "Teruel",
  },
  {
    localidad: "Terriente",
    provincia: "Teruel",
  },
  {
    localidad: "Toril y Masegoso",
    provincia: "Teruel",
  },
  {
    localidad: "Torres de Albarracín",
    provincia: "Teruel",
  },
  {
    localidad: "Tramacastilla",
    provincia: "Teruel",
  },
  {
    localidad: "Valdecuenca",
    provincia: "Teruel",
  },
  {
    localidad: "Vallecillo (El)",
    provincia: "Teruel",
  },
  {
    localidad: "Villar del Cobo",
    provincia: "Teruel",
  },
  {
    localidad: "Abejuela",
    provincia: "Teruel",
  },
  {
    localidad: "Albentosa",
    provincia: "Teruel",
  },
  {
    localidad: "Alcalá de la Selva",
    provincia: "Teruel",
  },
  {
    localidad: "Arcos de las Salinas",
    provincia: "Teruel",
  },
  {
    localidad: "Cabra de Mora",
    provincia: "Teruel",
  },
  {
    localidad: "Camarena de la Sierra",
    provincia: "Teruel",
  },
  {
    localidad: "Castellar (El)",
    provincia: "Teruel",
  },
  {
    localidad: "Formiche Alto",
    provincia: "Teruel",
  },
  {
    localidad: "Fuentes de Rubielos",
    provincia: "Teruel",
  },
  {
    localidad: "Gúdar",
    provincia: "Teruel",
  },
  {
    localidad: "Linares de Mora",
    provincia: "Teruel",
  },
  {
    localidad: "Manzanera",
    provincia: "Teruel",
  },
  {
    localidad: "Mora de Rubielos",
    provincia: "Teruel",
  },
  {
    localidad: "Mosqueruela",
    provincia: "Teruel",
  },
  {
    localidad: "Nogueruelas",
    provincia: "Teruel",
  },
  {
    localidad: "Olba",
    provincia: "Teruel",
  },
  {
    localidad: "Puebla de Valverde (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Puertomingalvo",
    provincia: "Teruel",
  },
  {
    localidad: "Rubielos de Mora",
    provincia: "Teruel",
  },
  {
    localidad: "San Agustín",
    provincia: "Teruel",
  },
  {
    localidad: "Sarrión",
    provincia: "Teruel",
  },
  {
    localidad: "Torrijas",
    provincia: "Teruel",
  },
  {
    localidad: "Valbona",
    provincia: "Teruel",
  },
  {
    localidad: "Valdelinares",
    provincia: "Teruel",
  },
  {
    localidad: "Arens de Lledó",
    provincia: "Teruel",
  },
  {
    localidad: "Beceite",
    provincia: "Teruel",
  },
  {
    localidad: "Calaceite",
    provincia: "Teruel",
  },
  {
    localidad: "Cretas",
    provincia: "Teruel",
  },
  {
    localidad: "Fórnoles",
    provincia: "Teruel",
  },
  {
    localidad: "Fresneda (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Fuentespalda",
    provincia: "Teruel",
  },
  {
    localidad: "Lledó",
    provincia: "Teruel",
  },
  {
    localidad: "Mazaleón",
    provincia: "Teruel",
  },
  {
    localidad: "Monroyo",
    provincia: "Teruel",
  },
  {
    localidad: "Peñarroya de Tastavins",
    provincia: "Teruel",
  },
  {
    localidad: "Portellada (La)",
    provincia: "Teruel",
  },
  {
    localidad: "Ráfales",
    provincia: "Teruel",
  },
  {
    localidad: "Torre de Arcas",
    provincia: "Teruel",
  },
  {
    localidad: "Torre del Compte",
    provincia: "Teruel",
  },
  {
    localidad: "Valdeltormo",
    provincia: "Teruel",
  },
  {
    localidad: "Valderrobres",
    provincia: "Teruel",
  },
  {
    localidad: "Valjunquera",
    provincia: "Teruel",
  },
];

export default localidades;
