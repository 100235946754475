<template>
  <!--begin::Card Body-->
  <div class="card-body p-10">
    <div class="row align-items-top desktop">
      <div class="fs-1">{{ $t("preguntasFrecuentes") }}</div>
      <div class="mt-2 fs-4">{{ $t("introFAQs") }}</div>
      <div class="col-lg-6 col-md-12">
        <div class="my-5 fs-3 fw-bold">{{ $t("sobreCursos") }}</div>
        <!--begin::Accordion-->
        <div class="accordion accordion-icon-toggle" id="kt_accordion_1">
          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_1"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta1") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_1"
              class="fs-6 collapse show ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta1") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_2"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta2") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_2"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta2") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_3"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta14") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_3"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta14") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_4"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta3") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_4"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta3") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

           <!--begin::Item-->
           <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_5"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta4") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_5"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta4") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

           <!--begin::Item-->
           <div class="mb-5">
              <!--begin::Header-->
              <div
                class="accordion-header py-3 d-flex collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_item_6"
              >
                <span class="accordion-icon">
                  <BIconArrowRightSquare class="fs-1 fw-bold" />
                </span>
                <h3 class="fs-4 fw-semibold mb-0 ms-4">
                  {{ $t("faqsCursosPregunta5") }}
                </h3>
              </div>
              <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_6"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta5") }} <br><br>
              {{ $t("faqsCursosRespuesta5p2") }} <br>
              {{ $t("faqsCursosRespuesta5p3") }} <br><br>
              {{ $t("faqsCursosRespuesta5p4") }} <br><br>
              {{ $t("faqsCursosRespuesta5p5") }} <br><br>
              {{ $t("faqsCursosRespuesta5p6") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

           <!--begin::Item-->
           <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_7"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta6") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_7"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta6") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

           <!--begin::Item-->
           <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_8"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta7") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_8"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta7") }} <br><br>
              {{ $t("faqsCursosRespuesta7p2") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_item_9"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta15") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_1_item_9"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_1"
            >
              {{ $t("faqsCursosRespuesta15") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->
        </div>
        <!--end::Accordion-->
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="my-5 fs-3 fw-bold">{{ $t("sobreIAM") }}</div>
        <!--begin::Accordion-->
        <div class="accordion accordion-icon-toggle" id="kt_accordion_2">
          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_8"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta8") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_2_item_8"
              class="fs-6 collapse show ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {{ $t("faqsCursosRespuesta8") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <!-- <div class="mb-5"> -->
            <!--begin::Header-->
            <!-- <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_9"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta9") }}
              </h3>
            </div> -->
            <!--end::Header-->

            <!--begin::Body-->
           <!--  <div
              id="kt_accordion_2_item_9"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {{ $t("faqsCursosRespuesta9") }}
            </div> -->
            <!--end::Body-->
          <!-- </div> -->
          <!--end::Item-->

          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_10"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta10") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_2_item_10"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {{ $t("faqsCursosRespuesta10") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

           <!--begin::Item-->
           <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_11"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta11") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_2_item_11"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {{ $t("faqsCursosRespuesta11") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

           <!--begin::Item-->
           <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_12"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta12") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_2_item_12"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {{ $t("faqsCursosRespuesta12") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

           <!--begin::Item-->
           <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_13"
            >
              <span class="accordion-icon">
                <BIconArrowRightSquare class="fs-1 fw-bold" />
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                {{ $t("faqsCursosPregunta13") }}
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_2_item_13"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              {{ $t("faqsCursosRespuesta13") }}
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="mb-5">
            <!--begin::Header-->
            <div
              class="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_2_item_14"
            >
              <span class="accordion-icon">
                <!--Aqui va el Icono --><br>
              </span>
              <h3 class="fs-4 fw-semibold mb-0 ms-4">
                <!--Aqui va el texto--><br>
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div
              id="kt_accordion_2_item_14"
              class="collapse fs-6 ps-10"
              data-bs-parent="#kt_accordion_2"
            >
              <!--Aqui va el parrafo--> <br>
            </div>
            <!--end::Body-->
          </div>
          <!--end::Item-->

        </div>
        <!--end::Accordion-->
      </div>
    </div>
  </div>
  <!--begin::Card Body-->
</template>
